import React, { useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Helmet as HelmetBase } from 'react-helmet';
import { DocsVerificationWidget, DocsVerificationWidgetProps } from '@dataspike/docver-widget';
import axios from 'axios';

import { Spinner } from './components/spinner';

import './app.css';
import './fonts.css';

const defaultAxios = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || '',
});

export const App: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const isInitialized = useRef(false);
    const isSettingsInitialized = useRef(false);
    const [settings, setSettings] = useState<{
        domain_settings: {
            api: string;
        };
    } | null>(null);
    const [domainPageTitle, setIsDomainPageTitle] = useState('');
    const [verificationId, setVerificationId] = useState('');
    const [apiUrl, setApiUrl] = useState(() => process.env.REACT_APP_API_BASE_URL);

    useEffect(() => {
        if (!isSettingsInitialized.current) {
            isSettingsInitialized.current = true;
            const getSettings = async () => {
                try {
                    const publicSettingsResponse = await defaultAxios.get(
                        '/webapi/wl/public-settings',
                    );
                    const { data } = publicSettingsResponse;

                    if (data?.logo_settings?.favicon_url) {
                        let link: HTMLLinkElement | null =
                            document.querySelector("link[rel~='icon']");

                        if (!link) {
                            link = document.createElement('link');
                            link.rel = 'icon';
                            document.querySelector('head')?.appendChild(link);
                        }
                        link.href = data.logo_settings.favicon_url;
                    }

                    if (data?.other_settings?.default_page_title) {
                        setIsDomainPageTitle(data.other_settings.default_page_title);
                    }

                    if (data) {
                        setSettings(data);
                    }

                    let newApiUrl = process.env.REACT_APP_API_BASE_URL || '';

                    if (data?.domain_settings?.api) {
                        newApiUrl = `https://${data.domain_settings.api}`;
                        setApiUrl(newApiUrl);
                    }

                    const { location } = window;

                    if (location.pathname.startsWith('/vp')) {
                        const linkId = location.pathname.replace('/vp/', '');
                        const verificationResponse = await axios.get(
                            `${newApiUrl}/api/v3/sdk/link/${linkId}`,
                        );

                        if (verificationResponse.data?.id) {
                            const { id } = verificationResponse.data;

                            setVerificationId(id);
                            window.history.replaceState('', '', `/${id}`);
                        }
                    } else {
                        setVerificationId(location.pathname.slice(1, location.pathname.length));
                    }
                } finally {
                    setIsLoading(false);
                }
            };

            getSettings();
        }
    }, []);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!isInitialized.current) {
            isInitialized.current = true;
            const isDsHost = window.location.hostname.includes('am.dataspike.io');

            if (process.env.REACT_APP_GTAG_MANAGER_ID && isDsHost) {
                TagManager.initialize({
                    gtmId: process.env.REACT_APP_GTAG_MANAGER_ID,
                });
            }

            DocsVerificationWidget({
                id: verificationId,
                elementId: 'widget',
                apiUrl,
                ...(settings ? { settings } : {}),
                ...(isDsHost
                    ? {
                          onFinish: () => {
                              TagManager.dataLayer({
                                  dataLayer: { event: 'verification_success' },
                              });
                          },
                      }
                    : {}),
            } as DocsVerificationWidgetProps);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return (
        <div className='app'>
            {!!domainPageTitle && (
                <HelmetBase>
                    <title>{domainPageTitle} - DocVer Live</title>
                    <meta property='og:title' content={`${domainPageTitle} - DocVer Live`} />
                </HelmetBase>
            )}
            {isLoading && <Spinner />}
            <div className='main-container'>
                <div id='widget' />
            </div>
        </div>
    );
};
